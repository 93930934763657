<template>
  <div class="center-badges">
    <b-row
      v-if="isFound('complete') === 'complete'"
      align="center"
      class="mt-1"
    >
      <b-col cols="12" class="badge badge-score-complete">
        {{ $t('views.playground.steps.step-2.time-zone-list.table.paymentMethods.complete' ) }}
      </b-col>
    </b-row>
    <b-row
      v-if="isFound('instalment') === 'instalment'"
      align="center"
      class="mt-1"
    >
      <b-col cols="12" class="badge badge-score-instalment">
        {{ $t('components.custom.playground.new.step-2.timezone.instalment') }}
      </b-col>
    </b-row>
    <b-row
      v-if="isFound('on_the_spot') === 'on_the_spot'"
      align="center"
      class="mt-1"
    >
      <b-col cols="12" class="badge badge-score-spot">
        {{ $t('views.playground.steps.step-2.time-zone-list.table.paymentMethods.on_the_spot') }}
      </b-col>
    </b-row>
    <b-row
      v-if="isFound('per_participant') === 'per_participant'"
      align="center"
      class="mt-1"
    >
      <b-col cols="12" class="badge badge-score-perParticipant">
        {{ $t('views.playground.steps.step-2.time-zone-list.table.paymentMethods.per_participant') }}
      </b-col>
    </b-row>
    <b-row align="center" v-if="isFound('deposit') === 'deposit'" class="mt-1">
      <b-col cols="12" class="badge badge-score-deposit">
        {{ $t('views.playground.steps.step-2.time-zone-list.table.paymentMethods.deposit') }}
      </b-col>
    </b-row>
  </div>
</template>
<script>
  export default {
    props: {
      paymentMethods: {
        type: Array,
        default: this,
      }
    },
    methods: {
      isFound (tag) {
        const findMethod = this.paymentMethods.find((el) => el === tag);

        if ("undefined" !== typeof findMethod) {
          return tag;
        } else {
          return '';
        }

      },
    }
  }
</script>
<style lang="scss" scoped>
.center-badges {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
